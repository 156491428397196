<template>
    <setting-layout :title="$t('pages.user.setting.creator.dream_goal.title')">
        <v-container>
            <div class="mb-4">
                <p>{{ $t("pages.user.setting.creator.dream_goal.description_details") }}</p>              
            </div>
            <v-form ref="form" class="auth-form mt-8">
                <v-row class="align-center">
                    <v-col>
                        <v-switch v-model="isDreamGoalActive">
                            <template #prepend>
                                <v-label>{{ $t('pages.user.setting.creator.subscriptions.default_active_label') }}</v-label>
                            </template>
                        </v-switch>
                    </v-col>
                </v-row>
                <v-row class="align-center">
                    <v-col cols="6">
                        <text-field
                            v-model="name"
                            :placeholder="$t('pages.user.setting.creator.dream_goal.enter_name')"
                            :error-messages="errors.name"
                            outlined
                        />
                    </v-col>
                    <v-col cols="5">
                        <text-field
                            :error-messages="errors.price"
                            placeholder="0-100"
                            :prepend="currency"
                            v-on:keyup.enter="save"
                            v-model="price"
                            outlined
                        />
                    </v-col>
                    <v-col cols="1" class="px-2">
                        <span @click="deleteTip()">
                            <app-icon icon="delete" size="28" />
                        </span>
                    </v-col>
                </v-row>   
                <div class="d-flex mt-2 align-center" v-if="isDreamGoalActive">
                <v-progress-linear class="me-2"
                    :value="progressGoal"
                    :width="5"
                    :color="progressGoalColor"
                    height="20"
                    striped
                    rounded
                >
                    <template v-slot:default>
                        <span class="dream-goal-name">{{ data.name }}</span>
                        <span class="dream-goal-price">{{ Number(data.collected_tips) }} / {{ Number(data.price) }} {{ currency }} </span>
                    </template>
                </v-progress-linear>
            </div>         
                <v-row justify="center" class="mt-8">
                    <gradient-button block @click="save" :loading="$store.state.buttonsLoading.DreamGoalBtn">
                        {{ $t('pages.user.setting.creator.dream_goal.save') }}
                    </gradient-button>
                </v-row>
            </v-form>
        </v-container>
    </setting-layout>
</template>

<script>
import SettingLayout from "@/layouts/app/profile/SettingLayout";
import TextField from "@/components/app/form/TextField";
import GradientButton from "@/components/app/button/GradientButton";
import {CURRENCY_SYMBOL} from "@/configs/constants";

import {mapActions, mapMutations, mapState} from "vuex";

export default {
    name: "DreamGoal",
    data: () => ({
        data: {},
        errors: {},
        name: '',
        price: '',
        currency: CURRENCY_SYMBOL,
        nameRules: [
            (v) => !!v || 'name is required',
        ],
        priceRules: [
            (v) => !!v || 'price is required',
        ],
        progressGoalColor: '#ff9100',
    }),
    computed: {
        ...mapState({
            user: state => state.user.user.data,
        }),
        isDreamGoalActive: {
            get() {
                return this.data?.is_activated === 1;
            },
            set(value) {
                if (this.data) {
                    this.data.is_activated = value ? 1 : 0;
                }
            }
        },
        progressGoal() {
            if (this.data) {
                return (this.data.collected_tips / this.data.price) * 100;
            }
        },
    },
    methods: {
        ...mapActions([
            'saveDreamGoal',
            'getDreamGoal',
            'deleteTipsMenu',
        ]),
        async save() {
            if (!this.$refs.form.validate()) {
                this.isLoading = false;
                return;
            }
            await this.saveDreamGoal({
                model: {
                    name: this.name,
                    price: this.price,
                    is_activated: this.data?.is_activated,
                }
            }).then((res) => {
                this.errors = {}
                this.data = res.data;
                this.showSnackBar(this.$t('pages.user.setting.creator.dream_goal.saved'))
            }).catch(err => {
                this.errors = err.response.data.errors
            });
        },
        deleteTip(){
            this.name = '';
            this.price = '';
        },
    },
    mounted() {
        this.getDreamGoal(this.$store.state.user.user.data.id)
            .then((res) => {
                this.data = res.data || {};
                this.name = res.data?.name;
                this.price = res.data?.price;
                this.isDreamGoalActive = res.data?.is_activated || 0; 
            })
    },
    components: {GradientButton, TextField, SettingLayout}
}
</script>

<style scoped>
.add {
    background: black;
    height: 48px;
    width: 48px;
    border-radius: 50%;
    font-size: 2.95em;
    color: white
}

.icon_plus {
    margin-bottom: 3px;
}

.dream-goal-name {
    font-size: 0.9em;
    font-weight: bold;
}

.dream-goal-price {
    position: absolute;
    right: 10px;
    font-size: 0.9em;
    font-weight: bold;
}

</style>
